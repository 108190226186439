<template>
    <div class="page page--notfound notfound">
        <div class="page__block">
            <h2>{{$t('not-found.title')}}</h2>
            <p>{{$t('not-found.text')}}</p>
            <p>{{$t('backto')}} <router-link to="/">Home</router-link></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound",

        mounted () {
            window.scrollTo(0, 0);
        }
    }
</script>